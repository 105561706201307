import * as React from "react"
import { useState, useEffect } from 'react'

import Layout from "src/components/layout"
import Seo from "src/components/seo"

import * as styles from "src/scss/_scrumfestniigata.module.scss"

import Logo from "src/images/scrumFestNiigata/renk-logo.svg"
import Re from "src/images/scrumFestNiigata/Re.svg"
import LogoSquare from "src/images/renk-logo-square.svg"
import HFGroupLogo from "src/images/hf-group-logo.svg"

import Renk1 from "src/images/scrumFestNiigata/renk1.jpg"
import Renk2 from "src/images/scrumFestNiigata/renk2.jpg"
import Renk3 from "src/images/scrumFestNiigata/renk3.jpg"
import Renk4 from "src/images/scrumFestNiigata/renk4.jpg"

import Decoration1 from "src/images/scrumFestNiigata/decoration1.svg"
import Decoration2 from "src/images/scrumFestNiigata/decoration2.svg"
import CloseIcon from "src/images/scrumFestNiigata/close-icon.svg"
import Arrow from "src/images/scrumFestNiigata/arrow.svg"

import { Parallax } from 'react-scroll-parallax';

export default function AboutLP () {
  const [showSolution1, setShowSolution1] = useState(false);
  const [showSolution2, setShowSolution2] = useState(false);
  const [showSolution3, setShowSolution3] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const isBrowser = typeof window !== "undefined"

  useEffect(() => {
    function handleResize() {
     if (isBrowser) {
        setIsSmallScreen(window.innerWidth < 821);
      }
    }

    if (isBrowser) {
      window.addEventListener('resize', handleResize);
    }

    handleResize();

    return () => {
      if (isBrowser) {
        window.removeEventListener('resize', handleResize);
      }
    }
  }, [isBrowser]);

  const changeSolution1Status = () => {
    setShowSolution1(!showSolution1);
  };

  const changeSolution2Status = () => {
    setShowSolution2(!showSolution2);
  };

  const changeSolution3Status = () => {
    setShowSolution3(!showSolution3);
  };

  const item1 = (
    <>
      <span>DX</span>
      <span>app</span>
      <span>WEB</span>
      <span>UI/UX</span>
      <span>POS</span>
      <span>AWS</span>
      <span>DESIGN</span>
      <span>API</span>
      <span>SQL</span>
      <span>IDE</span>
      <span>Cloud</span>
    </>
  )

  const example1 = [item1, item1, item1]

  const item2 = (
    <>
      <span>WEB</span>
      <span>UI/UX</span>
      <span>POS</span>
      <span>AWS</span>
      <span>DESIGN</span>
      <span>API</span>
      <span>SQL</span>
      <span>IDE</span>
      <span>Cloud</span>
      <span>DX</span>
      <span>app</span>
    </>
  )

  const example2 = [item2, item2, item2]

  const item3 = (
    <>
      <span>AWS</span>
      <span>DESIGN</span>
      <span>API</span>
      <span>SQL</span>
      <span>IDE</span>
      <span>Cloud</span>
      <span>DX</span>
      <span>app</span>
      <span>WEB</span>
      <span>UI/UX</span>
      <span>POS</span>
    </>
  )

  const example3 = [item3, item3, item3]

  return (
    <Layout>
      <Seo title="リンクチャネル株式会社" />
      <div className={styles.container}>
        <section className={styles.mainVisual}>
          <header className={styles.header}>
            <img src={ Logo } alt="リンクチャネル株式会社" width={214} height={45} />
          </header>
          <div className={styles.mvBox1}>
            <h1>CHALLENGE</h1>
          </div>
          <div className={styles.mvBox2}>
            <div className={styles.mvBox2Contents}>
              <div className={styles.newFutureWrapper}>
                <p>
                  新しいチャレンジをしつづけ、
                  <br />
                  <span></span>
                  社会に新しい価値を創出する
                </p>
                <h2>NEW FUTURE</h2>
              </div>
            </div>
          </div>
        </section>

        <div className={styles.mainVisualSPWrapper}>
          <header className={styles.header}>
            <img src={ Logo } alt="リンクチャネル株式会社" />
          </header>
          <div className={styles.mainVisualSP}>
            <div className={styles.leftPanel}>
              <p className={styles.mainVisualTxt}>社会に新しい価値を創出する</p>
              <div className={styles.newFuture}>
                <h2>NEW FUTURE</h2>
              </div>
            </div>
            <div className={styles.rightPanel}>
              <p className={styles.mainVisualTxt}>新しいチャレンジをしつづけ、</p>
              <h1 className={styles.challenge}>CHALLENGE</h1>
            </div>
          </div>
        </div>

        <section className={styles.aboutWrapper}>
          <div className={styles.aboutSlid}>
            <h2>We are the HARD OFF group We are the HARD OFF group</h2>
          </div>

          <div className={styles.aboutContent}>
            <div className={styles.aboutBox1}>
              <h2>リンクチャネルは<br />ハードオフグループの<br className={styles.br} />一員です。</h2>
              <div>
                <p>
                  2020年4月、ハードオフの取引先であったシステム開発会社を100％子会社化し「リンクチャネル株式会社」が誕生しました。
                </p>
                <p>
                  リンクチャネルでは、ハードオフグループの包括的なシステム開発・運用を始め、WEBからスマートフォンアプリまで、様々な開発を行っています。
                </p>
                <p>
                  私たちはハードオフグループの未来を創る為、スピード感を持って新しいチャレンジをし続けます。
                </p>
              </div>
            </div>
            <div className={styles.aboutBox2}>
              <div className={styles.card}>
                <div className={`${styles.logoWrapper} ${styles.groupLogo}`}>
                  <img src={ HFGroupLogo } alt="ハードオフグループ" loading="lazy" width={209} height={89} />
                </div>
              </div>
              <div className={styles.card}>
                <div className={`${styles.logoWrapper} ${styles.renkLogo}`}>
                  <img src={ LogoSquare } alt="リンクチャネル株式会社" loading="lazy" width={153} height={177} />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className={styles.solutionWrapper}>
          <h2>SOLUTION</h2>
          <div className={styles.solutionContent}>
            <Parallax speed={30} translateY={[10, -30]} disabled={isSmallScreen} className={styles.image1Wrapper}>
              <img src={ Renk1 } alt="" className={styles.image1} loading="lazy" width={600} height={403} />
            </Parallax>
            <div className={styles.aboutRenk}>
              <span className={styles.decoration1}>
                <img src={ Decoration1 } alt="" loading="lazy" width={73} height={71} />
              </span>
              <h3>"Re"NK <br />CHANNEL
                <span> とは</span>
              </h3>
              <p>ハードオフグループが掲げる店舗とお客様、そして働く社員とスタッフを繋ぐシステムを創り上げるため各チャネル間での繋がりを強化し、
                それぞれのチャネルへの流入を増加させることが 「”Re”NK CHANNEL構想」その実現のため誕生したのがリンクチャネル株式会社です。
              </p>
              <p>
                わたしたちは、システムを通じて利用する方に寄り添い新しい未来を創りたいという思いを持ち、包括的なシステム開発や運営保守を行っています。
              </p>
              <span className={styles.decoration2}>
                <img src={ Decoration2 } alt="" loading="lazy" width={73} height={71} />
              </span>
            </div>

            <div className={styles.imagesWrapper}>
              <Parallax speed={20} disabled={isSmallScreen} className={styles.image2Wrapper}>
                <img src={ Renk2 } alt="" className={styles.image2} loading="lazy" width={400} height={291} />
              </Parallax>
              <Parallax speed={15} disabled={isSmallScreen} className={styles.image3Wrapper}>
                <img src={ Renk3 } alt="" className={styles.image3} loading="lazy" width={350} height={292} />
              </Parallax>
              <Parallax speed={30} disabled={isSmallScreen} className={styles.image4Wrapper}>
                <img src={ Renk4 } alt="" className={styles.image4} loading="lazy" width={350} height={309} />
              </Parallax>
              {isSmallScreen &&
                <>
                  <div className={styles.image2Wrapper}>
                    <img src={ Renk2 } alt="" className={styles.image2} loading="lazy" width={400} height={291} />
                  </div>
                  <div className={styles.image3Wrapper}>
                    <img src={ Renk3 } alt="" className={styles.image3} loading="lazy" width={350} height={292} />
                  </div>
                  <div className={styles.image4Wrapper}>
                    <img src={ Renk4 } alt="" className={styles.image4} loading="lazy" width={350} height={309} />
                  </div>
                </>
              }
            </div>

            <div className={styles.solutionCardWrapper}>
              <div className={styles.solutionCardButtons}>
                <button onClick={changeSolution1Status}>
                  <span className={styles.solutionCardButton}>
                    {showSolution1 ? (
                      <span className={`${showSolution1? styles.slideUp : ""} ${styles.solution}`}>
                        POSシステム、店舗情報システム、製造設備システム、計測制御システムなどのシステムインテグレーションを手掛けております。
                      </span>
                    ) : (
                      <span className={`${!showSolution1? styles.slideDown : ""} ${styles.solutionTtlWrapper}`}>
                        <span className={styles.solutionTtl1}>INTEGRATION</span>
                        <span className={styles.solutionTtl2}>システムインテグレーション</span>
                      </span>
                    )}
                  </span>
                  <img src={ CloseIcon } alt="" loading="lazy" width={18} height={18}
                    className={`${showSolution1 ? styles.twistRight : styles.twistLeft}`} />
                </button>
                <button onClick={changeSolution2Status}>
                  <span className={styles.solutionCardButton}>
                    {showSolution2 ? (
                      <span
                        className={`${showSolution2? styles.slideUp : ""} ${styles.solution}`}
                      >
                        ショッピングシステム、顧客サービスシステム、予約システムなどサーバサイドアプリケーションの開発を手掛けております。
                      </span>
                    ) : (
                      <span className={`${!showSolution2? styles.slideDown : ""} ${styles.solutionTtlWrapper}`}>
                        <span className={styles.solutionTtl1}>WEB</span>
                        <span className={styles.solutionTtl2}>WEBシステム</span>
                      </span>
                    )}
                  </span>
                  <img src={ CloseIcon } alt="" loading="lazy" width={18} height={18}
                    className={`${showSolution2 ? styles.twistRight : styles.twistLeft}`} />
                </button>
                <button onClick={changeSolution3Status}>
                  <span className={styles.solutionCardButton}>
                    {showSolution3 ? (
                      <span className={`${showSolution3? styles.slideUp : ""} ${styles.solution}`}>
                        コンシューマー向けアプリを始め、ハードオフグループ内で使用する業務支援アプリなどの開発を行っています。
                      </span>
                    ) : (
                      <span className={`${!showSolution3? styles.slideDown : ""} ${styles.solutionTtlWrapper}`}>
                        <span className={styles.solutionTtl1}>app</span>
                        <span className={styles.solutionTtl2}>スマートフォンアプリ</span>
                      </span>
                    )}
                  </span>
                  <img src={ CloseIcon } alt="" loading="lazy" width={18} height={18}
                    className={`${showSolution3 ? styles.twistRight : styles.twistLeft}`} />
                </button>
              </div>
            </div>

            <div className={styles.solutionSlidWrapper}>
              <div className={styles.solutionSlidContent}>
                {example1.map((v, k) => <span key={k} className={styles.solutionSlidItem}>{v}</span>)}
              </div>
              <div className={styles.solutionSlidContent}>
                {example2.map((v, k) => <span key={k} className={styles.solutionSlidItem}>{v}</span>)}
              </div>
              <div className={styles.solutionSlidContent}>
                {example3.map((v, k) => <span key={k} className={styles.solutionSlidItem}>{v}</span>)}
              </div>
            </div>
          </div>
        </section>

        <section className={styles.recruitWrapper}>
          <div className={styles.recruitBox1}>
            <div className={styles.recruitBox1Content}>
              <h4>リンクチャネルでは採用を通年行っております。<br />
                新しいユーザー体験をあなたの手で創ってみませんか？
              </h4>

              <div className={styles.recruitLinkWrapper}>
                <h5>RECRUIT</h5>
                <a href="/recruit/">
                  <span className={styles.linkTtl}>
                    募集要項はこちら
                  </span>
                  <span className={styles.linkArrow}>
                    <img src={ Arrow } alt="" loading="lazy" width={20} height={8}/>
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div className={styles.recruitBox2}></div>
        </section>

        <footer className={styles.footerWrapper}>
          <div className={styles.footerContent}>
            <div className={styles.footerLogo}>
              <img src={ Logo } alt="リンクチャネル株式会社" loading="lazy" width={219} height={46} />
            </div>
            <small>&copy; ReNK CHANNEL Co., Ltd.</small>
          </div>
        </footer>
      </div>
    </Layout>
  )
}
