// extracted by mini-css-extract-plugin
export var container = "_scrumfestniigata-module--container--szv6e";
export var header = "_scrumfestniigata-module--header--3o7Fk";
export var mainVisualSPWrapper = "_scrumfestniigata-module--mainVisualSPWrapper--58PNm";
export var mainVisualSP = "_scrumfestniigata-module--mainVisualSP--NnxCQ";
export var leftPanel = "_scrumfestniigata-module--leftPanel--VIoAa";
export var newFuture = "_scrumfestniigata-module--newFuture--qDI-Y";
export var reWrapper = "_scrumfestniigata-module--reWrapper--u7Uli";
export var rightPanel = "_scrumfestniigata-module--rightPanel--VuV+y";
export var challenge = "_scrumfestniigata-module--challenge--OnBiS";
export var mainVisualTxt = "_scrumfestniigata-module--mainVisualTxt--hGEJJ";
export var mainVisual = "_scrumfestniigata-module--mainVisual--Cnw7F";
export var mvBox1 = "_scrumfestniigata-module--mvBox1--3ah1c";
export var mvBox2 = "_scrumfestniigata-module--mvBox2--UB5t3";
export var mvBox2Contents = "_scrumfestniigata-module--mvBox2Contents--8HlF7";
export var newFutureWrapper = "_scrumfestniigata-module--newFutureWrapper--VOvRv";
export var aboutWrapper = "_scrumfestniigata-module--aboutWrapper--HtUVW";
export var aboutSlid = "_scrumfestniigata-module--aboutSlid--bTZLZ";
export var moveRightToLeft = "_scrumfestniigata-module--moveRightToLeft--rCNR9";
export var aboutContent = "_scrumfestniigata-module--aboutContent--lwJD2";
export var aboutBox1 = "_scrumfestniigata-module--aboutBox1--FZtpU";
export var aboutBox2 = "_scrumfestniigata-module--aboutBox2--M9a6b";
export var br = "_scrumfestniigata-module--br--zdX7s";
export var card = "_scrumfestniigata-module--card--kzW7E";
export var logoWrapper = "_scrumfestniigata-module--logoWrapper--a-Fwx";
export var groupLogo = "_scrumfestniigata-module--groupLogo--VbRp0";
export var renkLogo = "_scrumfestniigata-module--renkLogo--iQL3b";
export var solutionWrapper = "_scrumfestniigata-module--solutionWrapper--zsMX4";
export var solutionContent = "_scrumfestniigata-module--solutionContent--DiyBX";
export var image1Wrapper = "_scrumfestniigata-module--image1Wrapper--7Tpsv";
export var image2Wrapper = "_scrumfestniigata-module--image2Wrapper--1kBL0";
export var image3Wrapper = "_scrumfestniigata-module--image3Wrapper--mjzn8";
export var image4Wrapper = "_scrumfestniigata-module--image4Wrapper--WWQZa";
export var image1 = "_scrumfestniigata-module--image1--uLETr";
export var image2 = "_scrumfestniigata-module--image2--CN4El";
export var image3 = "_scrumfestniigata-module--image3--fHqrK";
export var image4 = "_scrumfestniigata-module--image4--DUg+l";
export var imagesWrapper = "_scrumfestniigata-module--imagesWrapper--o-mVN";
export var moveRightToLeftForSolutionImages = "_scrumfestniigata-module--moveRightToLeftForSolutionImages--NI0xo";
export var moveRightToLeftForSolutionImagesSp = "_scrumfestniigata-module--moveRightToLeftForSolutionImagesSp--aE8xB";
export var aboutRenk = "_scrumfestniigata-module--aboutRenk--350LM";
export var decoration1 = "_scrumfestniigata-module--decoration1--8S6gD";
export var decoration2 = "_scrumfestniigata-module--decoration2--MWrI-";
export var solutionCardWrapper = "_scrumfestniigata-module--solutionCardWrapper--neOxv";
export var solutionCardButtons = "_scrumfestniigata-module--solutionCardButtons--Ufx3E";
export var slideDown = "_scrumfestniigata-module--slideDown--no+y8";
export var slideDownAnim = "_scrumfestniigata-module--slideDownAnim--3KURl";
export var slideUp = "_scrumfestniigata-module--slideUp--s8mIE";
export var slideUpAnim = "_scrumfestniigata-module--slideUpAnim--xe-67";
export var twistRight = "_scrumfestniigata-module--twistRight--ifAgF";
export var twistLeft = "_scrumfestniigata-module--twistLeft--cIkQj";
export var solutionCardButton = "_scrumfestniigata-module--solutionCardButton--bSOZV";
export var solution = "_scrumfestniigata-module--solution--kE4dy";
export var solutionTtlWrapper = "_scrumfestniigata-module--solutionTtlWrapper--eqcms";
export var solutionTtl1 = "_scrumfestniigata-module--solutionTtl1--hBawP";
export var solutionTtl2 = "_scrumfestniigata-module--solutionTtl2--11eSc";
export var solutionSlidWrapper = "_scrumfestniigata-module--solutionSlidWrapper--qZSGW";
export var solutionSlidContent = "_scrumfestniigata-module--solutionSlidContent--NBuIF";
export var solutionSlidItem = "_scrumfestniigata-module--solutionSlidItem--ehSnm";
export var recruitWrapper = "_scrumfestniigata-module--recruitWrapper--p4DCh";
export var recruitBox1 = "_scrumfestniigata-module--recruitBox1--lnZQd";
export var recruitBox1Content = "_scrumfestniigata-module--recruitBox1Content--wbMTu";
export var recruitLinkWrapper = "_scrumfestniigata-module--recruitLinkWrapper--w5cXG";
export var linkTtl = "_scrumfestniigata-module--linkTtl--1NMXu";
export var linkArrow = "_scrumfestniigata-module--linkArrow--00TyP";
export var recruitBox2 = "_scrumfestniigata-module--recruitBox2--IYPCC";
export var footerWrapper = "_scrumfestniigata-module--footerWrapper--3ofxJ";
export var footerContent = "_scrumfestniigata-module--footerContent--tPdUt";
export var footerLogo = "_scrumfestniigata-module--footerLogo--I22QY";